:root {
    --brand-blue: #0066ff;
    --brand-green: #00c346;
    --system-deepblack: #181A20;
    --system-black: #1F222A;
    --system-darkblue: #2B3D64;
    --system-deepdarkblue: #252932;
    --system-gray: #868686;
    --system-lightgray: #92A3B2;
    --system-morelightgray: #F1F3F5;


    --font-size-1: 80px;
    --font-size-2: 44px;
    --font-size-3: 32px;
    --font-size-4: 24px;
    --font-size-5: 20px;
    --font-size-6: 16px;
    --font-size-7: 14px;
    --font-size-8: 12px;

    --max-width: 500px;
}

@font-face {
    font-family: 'Pretendard';
    font-weight: normal;
    src: url('./fonts/Pretendard-Regular.ttf') format('truetype');
}

body {
    font-family: 'Pretendar', sans-serif;
    background: var(--system-deepblack);
    color: #FFF;
}

#root {
    max-width: var(--max-width);
    margin: 0 auto;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

